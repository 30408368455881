import React, { useState, useEffect } from 'react'
import '../scss/modal.scss'

const Modal: React.FC = () => {
    const [showModal, setShowModal] = useState<boolean>(false)

    useEffect(() => {
        const hasVisitedBefore = document.cookie.includes('hasVisitedBefore=true')
        if (!hasVisitedBefore) {
            setShowModal(true)
            const expirationDate = new Date()
            expirationDate.setTime(expirationDate.getTime() + 3600000) // Устанавливаем время жизни куки на 1 час
            document.cookie = `hasVisitedBefore=true; expires=${expirationDate.toUTCString()}`
        }
    }, [])

    const closeModal = () => {
        setShowModal(false)
    }

    return (
        <>
            {showModal && (
                <div className='modal-overlay'>
                    <div className='modal'>
                        <h2>Уважаемые партнеры!</h2>
                        <div className='text'>В
                            пятницу 16.08.24 компания ООО "ЕПГ" не работает по техническим причинам.</div>
                        <div className='description'>
                            Отгрузки
                            не производятся.<br />
                        </div>
                        <button onClick={closeModal} aria-label='close' className='x'>❌</button>
                    </div>
                </div>
            )}
        </>
    )
}

export default Modal

