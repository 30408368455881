import React from 'react'
import { Outlet } from 'react-router-dom'
import { Header } from '../Home/Header'
import { Footer } from '../Home/Footer'
import Modal from '../Modal'

const MainLayout: React.FC = () => {
    return (
        <div className='wrapper'>
            <Header />
            <Modal /> {/* Отключить после праздников */}

            <Outlet />

            <Footer />
        </div>
    )
}

export default MainLayout
