import vacImg from '../assets/img/vacancies.png'
import '../scss/vacancies.scss'
import { Helmet } from 'react-helmet'

const Vacancies: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>Вакансии - ЕвроПромГидравлик</title>
                <meta name='description'
                      content='Вакансии в компании ЕвроПромГидравлик' />
            </Helmet>
            <h1>Вакансии</h1>
            <div className='vacancies'>
                <div className='vacancies__text'>
                    <p>Компании ЕвроПромГидравлик требуется:</p>
                    <div className='vacancies__text-vac'>
                        <p>В настоящее время свободных вакансий нет.</p>
                    </div>
                    <div className='vacancies__text-contacts'>
                        <p>По всем вопросам обращаться:</p>
                        <p>Тел: +7 (812) 336 29 37</p>
                        <p>Email: info@avs-rus.ru</p>
                    </div>
                </div>
                <div className='vacancies__image'>
                    <img src={vacImg} alt='Вакансии в ЕвроПромГидравлик' />
                </div>
            </div>
        </>
    )
}

export default Vacancies
